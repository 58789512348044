import { AxiosResponse } from "axios";
import { CompanyGlobalAdminOptionsEnum, PROXY_PREFIX } from "src/constants";
import { TCompanyDataDetails } from "../pages/companyDetails/useGetCompanyDetails.hook";
import { TCompany } from "../store/slices/companiesSlice";
import axiosInstance from "../utils/axios/axiosInstance";
import { TCompanyShortInfo } from "../globalTypes";

type TGetCompaniesRes = {
  success: boolean;
  companies: TCompany[];
};

export const getCompaniesApi = (): Promise<AxiosResponse<TGetCompaniesRes>> => {
  return axiosInstance.get(`/api/secure/company/list`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteCompanyApi = (
  companyId: number,
  removeFiles: boolean
): Promise<AxiosResponse<TGetCompaniesRes>> => {
  return axiosInstance.delete(`/api/secure/company/${companyId}`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
      removeFiles,
    },
  });
};

type TCreateCompanyRes = {
  success: boolean;
  companyId: number;
};
export const createCompanyApi = (
  formData: FormData
): Promise<AxiosResponse<TCreateCompanyRes>> => {
  return axiosInstance.post(`/api/secure/company`, formData, {
    headers: { "content-type": "multipart/form-data" },
  });
};

type TUpdateCompanyRes = TCreateCompanyRes;
export const updateCompanyApi = (
  formData: FormData,
  companyId: number
): Promise<AxiosResponse<TUpdateCompanyRes>> => {
  return axiosInstance.put(`/api/secure/company/${companyId}`, formData, {
    headers: { "content-type": "multipart/form-data" },
    params: {
      companyId,
    },
  });
};

export const markCompanyApi = (
  companyId: number,
  mark: boolean
): Promise<AxiosResponse<TUpdateCompanyRes>> => {
  return axiosInstance.put(`/api/secure/company/${companyId}/mark`, { mark });
};

export const getCompanyApi = (
  companyId: number
): Promise<AxiosResponse<TCompanyDataDetails>> => {
  return axiosInstance.get(`/api/secure/company/${companyId}`, {
    headers: { "content-type": "application/json" },
  });
};

type GetCompanyParams = {
  name: string;
  pageNumber: number;
  pageSize: number;
};

export type CMCompanyItem = {
  id: number;
  naturalId: string;
  name: string;
};

export type TSearchCompaniesRes = {
  totalItems: number;
  items: CMCompanyItem[];
};

export const searchCompaniesApi = (
  params: GetCompanyParams
): Promise<AxiosResponse<TSearchCompaniesRes>> => {
  return axiosInstance.get(`${PROXY_PREFIX}/api/secured/company/`, {
    params,
  });
};

export const getCompaniesSearchApi = ({
  pageNumber,
  pageSize,
}: {
  pageNumber: number;
  pageSize: number;
}): Promise<
  AxiosResponse<{
    list: TCompany[];
    total: number;
  }>
> => {
  return axiosInstance.get(
    `/api/secure/company/search?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export type TCompanyOption = {
  id: {
    extCompanyId: number;
    key: string;
  };
  value: string | null;
};
export const getCompanyOptionsApi = (
  companyId: number
): Promise<AxiosResponse<TCompanyOption[]>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/company/${companyId}/option/`,
    {
      headers: { "content-type": "application/json" },
    }
  );
};

export const saveCompanyOptionApi = ({
  companyId,
  key,
  checked,
}: {
  companyId: number;
  key: CompanyGlobalAdminOptionsEnum;
  checked: boolean;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.post(
    `${PROXY_PREFIX}/api/secured/company/${companyId}/option/${key}/set`,
    { checked },
    {
      headers: { "Content-Type": "application/json" },
      params: {
        companyId,
      },
    }
  );
};

export const getCompanyOptionApi = ({
  companyId,
  key,
}: {
  companyId: number;
  key: CompanyGlobalAdminOptionsEnum;
}): Promise<AxiosResponse<string | TCompanyOption>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/company/${companyId}/option/${key}`,
    {
      headers: { "Content-Type": "application/json" },
      params: {
        companyId,
      },
    }
  );
};

export const getCompanyDataApi = ({
  companyId,
}: {
  companyId: string;
}): Promise<AxiosResponse<TCompanyShortInfo>> => {
  return axiosInstance.get(`${PROXY_PREFIX}/api/secured/company/${companyId}`, {
    headers: { "Content-Type": "application/json" },
    params: {
      companyId,
    },
  });
};
