import { Empty } from "antd";
import { FC } from "react";
import { UserDrawerProps } from "../../../pages/users/hooks/useGetUsers.hook";
import { TUsersListItem } from "../../../store/slices/usersSlice";
import { Table, TableBody, TableRow } from "../../common/ui/table";
import UsersListItem from "../UsersListItem/UsersListItem";
import { TableHeader } from "src/components/table/Header";

type Props = {
  users: TUsersListItem[];
  userDrawerProps: UserDrawerProps;
  setUserDrawerProps: (props: UserDrawerProps) => void;
  refreshUsers: () => Promise<void>;
};

const headerClassName = "font-sans font-bold text-[#0F172A]";
const tableHeaderItems = [
  {
    title: "User",
    className: headerClassName,
  },
  {
    title: "Email",
    className: headerClassName,
  },
  {
    title: "Company",
    className: headerClassName,
  },
  {
    title: "Role",
    className: headerClassName,
  },
  {
    title: "Department",
    className: headerClassName,
  },
  null,
];

const UsersList: FC<Props> = ({ users, setUserDrawerProps, refreshUsers }) => {
  if (!users.length) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <Empty
          imageStyle={{ height: "30vh" }}
          description={
            <span className="font-sans font-bold text-[#0F172A]">
              There are no users in your workspace.
            </span>
          }
        ></Empty>
      </div>
    );
  }

  return (
    <div className="rounded-md border w-full mx-auto">
      <Table>
        <TableHeader items={tableHeaderItems} />
        <TableBody className="font-sans">
          {users.length ? (
            users.map((item) => (
              <UsersListItem
                key={item.id}
                item={item}
                setUserDrawerProps={setUserDrawerProps}
                refreshUsers={refreshUsers}
              />
            ))
          ) : (
            <TableRow>No results.</TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default UsersList;
