import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { AppDispatch } from "../../../store/store";
import { selectUsersListState, setPageNumber, setPageSize, setUsers } from "../../../store/slices/usersListSlice";
import handleRequestError from "../../../utils/handleRequestError";
import { getUsersListApi } from "../../../api/user.api";

export type UserDrawerProps = {
  visible: boolean;
  userId?: number;
  fullName?: string;
};

const useGetUsersHook = () => {
  const dispatch : AppDispatch  = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const [userDrawerProps, setUserDrawerProps] = useState<UserDrawerProps>({
    visible: false,
  });
  const [isFetching, setIsFetching] = useState(false);
  const { isRegularUser } = useCurrentUser();
  const { users, total, pageNumber, pageSize } = useSelector(
    selectUsersListState
  );

  useEffect(() => {
    loadUsers({ pageNumber, pageSize });
  }, [pageNumber, pageSize]);

  const loadUsers = async ({
    pageNumber,
    pageSize,
  }: {
    pageNumber: number;
    pageSize: number;
  }) => {
    try {
      setIsFetching(true);

      const { data } = await getUsersListApi({
        pageSize,
        pageNumber,
      });

      const { users, totalUsers } = data.users;
      dispatch(setUsers({ users, total: totalUsers } ));
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const updatePage = (pageNumber: number) => {
    dispatch(setPageNumber(pageNumber));
  };

  const updatePageSize = (pageSize: number) => {
    dispatch(setPageNumber(1));
    dispatch(setPageSize(pageSize));
  };

  const refreshUsers = async () => {
    await loadUsers({ pageNumber, pageSize });
  };

  return {
    users,
    userDrawerProps,
    setUserDrawerProps,
    isFetching,
    isRegularUser,
    total,
    pageSize,
    pageNumber,
    updatePage,
    updatePageSize,
    refreshUsers,
  };
};

export default useGetUsersHook;
