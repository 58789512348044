import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCampaignsApi } from "../../api/campaigns.api";
import { getCompanyApi } from "../../api/companies.api";
import { CompanyDrawerProps } from "../../components/companies/CompanyDrawer/CompanyDrawer";
import { selectMessageApi } from "../../store/slices/appSlice";
import { TUserData } from "../../store/slices/usersSlice";
import handleRequestError from "../../utils/handleRequestError";

export type TCompanyDataDetails = {
  company: {
    id: number;
    website: string;
    logo: null | string;
    founding_at: string;
    name: string;
    mark: boolean;
  };
  users: Array<Omit<TUserData, "global_admin_api_key">>;
};

const useGetCompanyDetailsHook = () => {
  const { companyId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [activeCampaigns, setActiveCampaigns] = useState(0);
  const [companyData, setCompanyData] = useState<TCompanyDataDetails | null>(
    null
  );
  const [companyDrawerProps, setCompanyDrawerProps] =
    useState<CompanyDrawerProps>({
      visible: false,
    });

  useEffect(() => {
    if (companyId) {
      getCompanyData(+companyId);
    } else {
      messageApi.error("Couldn't load the page (company id is missing)");
    }
  }, []);

  const getCompanyData = async (companyId: number) => {
    try {
      const { data: company } = await getCompanyApi(companyId);

      //TODO need separate api for only total campaigns.
      const { data: campaigns } = await getCampaignsApi({
        pageSize: 1000,
        companyId,
        currentPage: 1,
        campaignType: "ALL",
      });

      setActiveCampaigns(campaigns.totalItems);
      setCompanyData(company);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
      setCompanyData(null);
    }
  };

  return {
    companyData,
    companyDrawerProps,
    setCompanyDrawerProps,
    getCompanyData,
    messageApi,
    activeCampaigns,
  };
};

export default useGetCompanyDetailsHook;
