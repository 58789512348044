import { Typography } from "antd";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ArrowUpTrayIcon,
  ArrowUturnLeftIcon,
  CloneIcon,
  DeleteIcon,
  Icons,
} from "src/components/common/Icons";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import { Badge } from "src/components/common/ui/badge";
import { Button } from "src/components/common/ui/button";
import { TableCell, TableRow } from "src/components/common/ui/table";
import { campaignBadgeSettings } from "src/constants";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { TCampaignListElem } from "../../globalTypes";
import useConfirm from "../../hooks/useConfirm";
import { getMessageApi } from "../../store/slices/appSlice";
import {
  cloneAndOpenCampaignThunk,
  deleteCampaignThunk,
  markCampaignThunk,
  publishCampaignThunk,
} from "../../store/slices/campaignsSlice";
import { AppDispatch } from "../../store/store";
import { getCampaignNameValidationRule } from "../../utils/validators";
import CloneEntityModal, {
  TCloneEntityFormValues,
} from "../common/modals/CloneEntityModal/CloneEntityModal";
import DeleteWithFilesModal from "../common/modals/DeleteWithFilesModal/DeleteWithFilesModal";
import StartCampaignModal from "./StartCampaignModal/StartCampaignModal";
import { BookmarkSquareIcon } from "@heroicons/react/16/solid";

type PropsType = {
  item: TCampaignListElem;
  onReloadCurrentList: () => void;
};

export const CampaignListItem: FC<PropsType> = ({
  item,
  onReloadCurrentList,
}) => {
  const { id, companyName, title, campaignType, companyId, mark } = item;
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const messageApi = getMessageApi();
  const { isGlobalAdmin } = useCurrentUser();
  const confirm = useConfirm();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const campaignNameValidationRule = getCampaignNameValidationRule();

  const handleDelete = async (removeFiles: boolean) => {
    try {
      await dispatch(
        deleteCampaignThunk({ campaignId: id, removeFiles })
      ).unwrap();

      onReloadCurrentList();

      setIsDeleteModalOpen(false);

      messageApi.success("The campaign has been successfully removed");
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(
        "An error occurred while trying to delete the campaign:",
        e
      );
    }
  };

  const onEditCampaign = async () => {
    navigate(
      `/admin/campaigns/company/${
        companyId !== null ? companyId : "global"
      }/campaign/${id}`
    );
  };

  const onCloneCampaign = async (values: TCloneEntityFormValues) => {
    try {
      const clonedCampaignId = await dispatch(
        cloneAndOpenCampaignThunk({
          campaignId: id,
          companyId: companyId === null ? undefined : companyId,
          params: {
            ...values,
            name: values.name.replace(/ +/g, " "),
          },
        })
      ).unwrap();

      navigate(
        `/admin/campaigns/company/${
          companyId !== null ? companyId : "global"
        }/campaign/${clonedCampaignId}`
      );

      messageApi.success("The campaign has been successfully cloned");
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error("An error occurred while trying to clone the campaign:", e);
    }
  };

  const publishCampaign = async () => {
    try {
      await dispatch(
        publishCampaignThunk({
          campaignId: id,
          type: campaignType,
          onPublished: function (type) {
            messageApi.success(
              `The campaign has been successfully ${
                type === "PUBLISHED_TEMPLATE" ? "unpublished" : "published"
              }`
            );
          },
        })
      ).unwrap();
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error("An error occurred while trying to publish campaign:", e);
    }
  };

  const markCampaign = async () => {
    try {
      await dispatch(
        markCampaignThunk({ campaignId: id, markStatus: !mark })
      ).unwrap();
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error("An error occurred while trying to mark the campaign:", e);
    }
  };

  const menuItems = [
    campaignType === "DRAFT_TEMPLATE" && {
      key: "publish",
      label: "Publish",
      icon: ArrowUpTrayIcon,
      onClick: () =>
        confirm({ action: publishCampaign, title: "Publish Template" }),
      className: "!text-primary",
    },
    campaignType === "PUBLISHED_TEMPLATE" && {
      key: "create",
      label: "Create Campaign",
      icon: Icons.Plus,
      onClick: () => setIsCreateModalOpen(true),
      className: "!text-primary",
    },
    campaignType === "PUBLISHED_TEMPLATE" && {
      key: "unpublish",
      label: "Unpublish",
      icon: ArrowUturnLeftIcon,
      onClick: () =>
        confirm({ action: publishCampaign, title: "Unpublish Template" }),
    },
    isGlobalAdmin && {
      key: "mark",
      label: item.mark ? "Unmark" : "Mark",
      icon: ArrowUturnLeftIcon,
      onClick: markCampaign,
    },
    {
      key: "clone",
      label: "Clone",
      icon: CloneIcon,
      onClick: () => setIsCloneModalOpen(true),
    },
    {
      key: "delete",
      label: "Delete",
      onClick: () => setIsDeleteModalOpen(true),
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  return (
    <>
      <TableRow
        key={id}
        onClick={onEditCampaign}
        className="cursor-pointer relative"
      >
        <TableCell>
          <span className="flex items-center">
            {title}
            {!!mark && isGlobalAdmin && (
              <BookmarkSquareIcon className="size-[20px] ml-1" />
            )}
          </span>
        </TableCell>
        <TableCell className={!isGlobalAdmin ? "p-2" : ""}>
          {!!companyName ? (
            <span>{companyName}</span>
          ) : (
            <Typography.Text code style={{ color: "orange", opacity: 0.7 }}>
              Global
            </Typography.Text>
          )}
        </TableCell>
        <TableCell>
          <Badge
            className={campaignBadgeSettings[campaignType].className}
            variant={"table"}
          >
            {campaignBadgeSettings[campaignType].label}
          </Badge>
        </TableCell>
        <TableCell
          className="flex items-center justify-center"
          onClick={(e) => e.stopPropagation()}
        >
          {isGlobalAdmin && (
            <MenuDropdown
              items={menuItems}
              dropdownClassName="w-[190px]"
              align="end"
            >
              <Button variant={"ghostPrimary"} className="size-[40px] p-2">
                <Icons.MoreDots className="size-[24px]" />
              </Button>
            </MenuDropdown>
          )}
        </TableCell>
      </TableRow>
      {isDeleteModalOpen && (
        <DeleteWithFilesModal
          entity="Campaign"
          onDelete={handleDelete}
          title={title}
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
        />
      )}

      {isCreateModalOpen && (
        <StartCampaignModal
          campaignTemplateName={item.title}
          setIsModalOpen={setIsCreateModalOpen}
          campaignId={item.id}
          isModalOpen={isCreateModalOpen}
          campaignNameValidationRule={campaignNameValidationRule}
        />
      )}

      {isCloneModalOpen && (
        <CloneEntityModal
          entity="Campaign"
          entityTitle={title}
          entityTitleTooltip="Please enter a string between 4 and 255 characters."
          nameValidationRule={campaignNameValidationRule}
          onSubmitForm={onCloneCampaign}
          isModalOpen={isCloneModalOpen}
          onCancel={() => setIsCloneModalOpen(false)}
        />
      )}
    </>
  );
};

export default CampaignListItem;
