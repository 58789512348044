import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { CampaignViewTypes } from "src/types";
import { getAssetsApi } from "../../api/assets.api";
import { getCampaignLabelsApi } from "../../api/campaign-labels.api";
import {
  cloneCampaignApi,
  createCampaignApi,
  deleteCampaignApi,
  editCampaignApi,
  getCampaignsApi,
  getCampaignsDataApi,
  markCampaignApi,
  publishCampaignApi,
  redeployCampaignToMicroSiteApi,
  unPublishCampaignApi,
} from "../../api/campaigns.api";
import { getCcVarsApi } from "../../api/cc-variables.api";
import { getCompanyApi, getCompanyOptionApi } from "../../api/companies.api";
import { computationApi } from "../../api/computation.api";
import { getGlobalLabelsApi } from "../../api/global-labels.api";
import { getPermissionsApi } from "../../api/user.api";
import { TCloneEntityFormValues } from "../../components/common/modals/CloneEntityModal/CloneEntityModal";
import { CompanyGlobalAdminOptionsEnum } from "../../constants";
import { TAsset, TPhase } from "../../globalTypes";
import { getStepPathMap, sortBySequence } from "../../utils/cm.utils";
import {
  handleRequestError,
  TCustomError,
} from "../../utils/handleRequestError";
import transformCampaignData from "../../utils/transformCampaignData";
import { RootState } from "../store";
import { toggleIsFetching } from "./appSlice";
import { setCampaignLabels } from "./campaignLabelsSlice";
import { setCampaignListTotalCampaigns } from "./campaignsListSlice";
import { loadDynamicItemsThunk, setCcVariables } from "./ccVariablesSlice";
import { setCurrentCompany, TCompany } from "./companiesSlice";
import { setGlobalLabels } from "./globalLabelsSlice";
import { cleanUpPhases, setCurrentPhase, setPhases } from "./phasesSlice";
import {
  cleanUpSteps,
  setCurrentStep,
  setStepPathMap,
  setSteps,
  TStep,
} from "./stepsSlice";
import { setUserPermissions, TPermissions } from "./usersSlice";

export type TCampaign = {
  id: number;
  extCompanyId: number | null;
  extCompanyName: string | null;
  naturalId: string;
  type: TCampaignType;
  name: string;
  microSiteContextFolder: null | string;
  aiModelId?: number | null;
  mark: boolean;
};

export type CampaignDataAssetsStructure = TCampaign & {
  phases: (TPhase & { steps: (TStep & { assets: TAsset[] })[] })[];
};

export type TCampaignType =
  | "CAMPAIGN"
  | "DRAFT_TEMPLATE"
  | "PUBLISHED_TEMPLATE";

//TODO check TCampaignResponseData - response changed with new values
export type TCampaignResponseData = Omit<TCampaign, "companyName">;

type deleteCampaignProps = {
  campaignId: number;
  removeFiles: boolean;
};

const initialState = {
  currentView: CampaignViewTypes.GRID,
  current: null as TCampaign | null,
  list: [] as Array<TCampaign>,
  isRerunCampaignEnabled: false,
};

type InitialStateType = typeof initialState;

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setCampaigns: (
      state: InitialStateType,
      action: PayloadAction<Array<TCampaign>>
    ) => {
      state.list = action.payload;
    },
    addCampaign: (
      state: InitialStateType,
      action: PayloadAction<TCampaign>
    ) => {
      state.list.push(action.payload);
    },
    setCurrentCampaignView: (
      state: InitialStateType,
      action: PayloadAction<CampaignViewTypes>
    ) => {
      state.currentView = action.payload;
    },
    setCurrentCampaign: (
      state: InitialStateType,
      action: PayloadAction<TCampaign | null>
    ) => {
      state.current = action.payload;
    },
    updateCampaign: (
      state: InitialStateType,
      action: PayloadAction<TCampaign>
    ) => {
      const editedCampaign = action.payload;
      const index = state.list.findIndex(
        (campaign) => campaign.id === editedCampaign.id
      );

      state.list[index] = editedCampaign;
    },
    updateCampaignMark: (
      state: InitialStateType,
      action: PayloadAction<{ campaignId: number; mark: boolean }>
    ) => {
      const { campaignId, mark } = action.payload;
      const index = state.list.findIndex((campaign) => campaign.id === campaignId);
      state.list[index].mark = mark;
    },
    deleteCampaign: (
      state: InitialStateType,
      action: PayloadAction<number>
    ) => {
      state.list = state.list.filter(
        (campaign) => campaign.id !== action.payload
      );
    },
    setIsRerunCampaignEnabled: (
      state: InitialStateType,
      action: PayloadAction<boolean>
    ) => {
      state.isRerunCampaignEnabled = action.payload;
    },
  },
});

export const {
  setCampaigns,
  addCampaign,
  setCurrentCampaign,
  updateCampaign,
  deleteCampaign,
  setIsRerunCampaignEnabled,
  setCurrentCampaignView,
  updateCampaignMark,
} = campaignsSlice.actions;

export default campaignsSlice.reducer;

export const selectCampaignsList = (state: RootState) => state.campaigns.list;
export const selectCurrentCampaign = (state: RootState) =>
  state.campaigns.current;
export const selectCurrentCampaignView = (state: RootState) =>
  state.campaigns.currentView;

/* eslint-disable*/
export const useCampaigns = (): TCampaign[] => useSelector(selectCampaignsList);
export const getCurrentCampaign = (): TCampaign | null =>
  useSelector(selectCurrentCampaign);
export const selectIsRerunCampaignEnabled = (state: RootState) =>
  state.campaigns.isRerunCampaignEnabled;

type TLoadCampaignDataThunk = {
  companyIdFromUrl: string | undefined;
  companyId: number | undefined;
  campaignId: number;
  phaseId: number | undefined;
  stepId: number | undefined;
};
export const loadCampaignDataThunk = createAsyncThunk<
  string | undefined,
  TLoadCampaignDataThunk,
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "Campaigns/loadCampaignDataThunk",
  async (
    { campaignId, phaseId, stepId, companyId, companyIdFromUrl },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(toggleIsFetching(true));

      const { users } = getState();
      const permissions: TPermissions = {
        list: [],
        cmApiKey: null,
      };
      let currentCompany: TCompany | null = null;

      //if company is not global we load permissions by id
      if (companyId !== undefined) {
        const { data } = await getPermissionsApi(companyId);
        const { data: companyDetails } = await getCompanyApi(companyId);
        currentCompany = {
          id: companyDetails.company.id,
          name: companyDetails.company.name,
          website: companyDetails.company.website,
          founding_at: companyDetails.company.founding_at,
          mark: companyDetails.company.mark,
        };

        permissions.list = data.permissions;
        permissions.cmApiKey = data.cmApiKey;

        if (users.userData.role === "admin") {
          const { data } = await getCompanyOptionApi({
            companyId,
            key: CompanyGlobalAdminOptionsEnum.ENABLE_ADMIN_RERUN,
          });
          if (data && typeof data === "object") {
            try {
              const rerunCampaignOption =
                data.value && JSON.parse(data.value).checked;

              if (rerunCampaignOption) {
                dispatch(setIsRerunCampaignEnabled(rerunCampaignOption));
              }
            } catch (e) {
              console.error("Can't parse company option data", e);
            }
          }
        }
      } else {
        permissions.cmApiKey = users.userData.global_admin_api_key;
      }

      const { data: campaignData } = await getCampaignsDataApi(
        campaignId,
        companyId
      );
      const { campaign, phases, steps, step, phase } = transformCampaignData(
        campaignData,
        phaseId,
        stepId
      );
      const stepPathMap = getStepPathMap(campaignData.phases);

      await dispatch(loadDynamicItemsThunk({ companyId })).unwrap();

      dispatch(setSteps(steps));
      dispatch(setStepPathMap(stepPathMap));
      dispatch(setCurrentStep(step));
      dispatch(setPhases(phases));
      dispatch(setCurrentPhase(phase));
      dispatch(setCurrentCampaign(campaign));
      dispatch(setCurrentCompany(currentCompany));
      dispatch(setUserPermissions(permissions));

      if (phase && step) {
        //return the full URL if the phase and step have been successfully loaded
        return `/admin/campaigns/company/${companyIdFromUrl}/campaign/${campaignId}/phase/${phase.id}/step/${step.id}`;
      }

      if (!phase) {
        //return the redirect path to the campaign if the phase has not been loaded
        return `/admin/campaigns/company/${companyIdFromUrl}/campaign/${campaignId}`;
      } else if (!step) {
        //return the redirect path to the phase if the step has not been loaded
        return `/admin/campaigns/company/${companyIdFromUrl}/campaign/${campaignId}/phase/${phase.id}`;
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to load campaign data:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

type TLoadCampaignDataByStepThunk = {
  companyId: number | undefined;
  campaignId: number;
  stepId: number;
};

export const loadCampaignDataByStepThunk = createAsyncThunk<
  { assets: TAsset[] },
  TLoadCampaignDataByStepThunk,
  {
    rejectValue: TCustomError;
  }
>(
  "Campaigns/loadCampaignDataByStepThunk",
  async ({ campaignId, stepId, companyId }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const [globalLabels, campaignLabels, ccVars, assets] = await Promise.all([
        getGlobalLabelsApi("en", companyId),
        getCampaignLabelsApi(campaignId, "en", companyId),
        getCcVarsApi(campaignId, stepId, companyId),
        getAssetsApi({ campaignId, stepId, companyId }),
      ]);

      const sortedCcVars = sortBySequence(ccVars.data);

      dispatch(setGlobalLabels(globalLabels.data));
      dispatch(setCampaignLabels(campaignLabels.data));
      dispatch(setCcVariables(sortedCcVars));

      return {
        assets: assets.data,
      };
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to load campaign data:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const publishCampaignThunk = createAsyncThunk<
  undefined,
  {
    campaignId: number;
    type: TCampaignType;
    onPublished: (type: TCampaignType) => void;
  },
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "Campaigns/publishCampaign",
  async (
    { campaignId, type, onPublished },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(toggleIsFetching(true));

      const { campaignsList, companies } = getState();
      const companyId = companies.current?.id;

      if (type === "DRAFT_TEMPLATE") {
        await publishCampaignApi(campaignId, companyId);
        onPublished?.("DRAFT_TEMPLATE");
      } else {
        await unPublishCampaignApi(campaignId, companyId);
        onPublished?.(type);
      }

      const { data: campaigns } = await getCampaignsApi({
        companyId: null,
        currentPage: 1,
        pageSize: 100,
        campaignType: "ALL",
      });

      dispatch(setCampaigns(campaigns.items));
      dispatch(setCampaignListTotalCampaigns(campaigns.totalItems));

      dispatch(cleanUpPhases());
      dispatch(cleanUpSteps());
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to publish campaign:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const markCampaignThunk = createAsyncThunk<
  void,
  { campaignId: number; markStatus: boolean },
  { state: RootState; rejectValue: TCustomError }
>(
  "Campaigns/markCampaign",
  async (
    { campaignId, markStatus },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const { companies } = getState();
      const companyId = companies.current?.id || null;

      await markCampaignApi(campaignId, companyId, markStatus);

      dispatch(updateCampaignMark({ campaignId, mark: markStatus }));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to mark/unmark the campaign:`,
        e
      );

      return rejectWithValue(customError);
    }
  }
);

export const cloneAndOpenCampaignThunk = createAsyncThunk<
  number,
  {
    campaignId: number;
    companyId: number | undefined;
    params: TCloneEntityFormValues;
  },
  {
    rejectValue: TCustomError;
  }
>(
  "Campaigns/cloneCampaign",
  async ({ campaignId, companyId, params }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { data: newCampaign } = await cloneCampaignApi({
        campaignId,
        companyId,
        ...params,
      });

      return newCampaign.id;
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to clone the campaign:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const deleteCampaignThunk = createAsyncThunk<
  undefined,
  deleteCampaignProps,
  { state: RootState; rejectValue: TCustomError }
>(
  "Campaigns/deleteCampaign",
  async (
    { campaignId, removeFiles },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const { companies } = getState();
      await deleteCampaignApi(campaignId, companies.current?.id, removeFiles);

      dispatch(cleanUpPhases());
      dispatch(cleanUpSteps());
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to delete the campaign:`,
        e
      );

      return rejectWithValue(customError);
    }
  }
);

export const createCampaignThunk = createAsyncThunk<
  TCampaign,
  { companyId: number | null },
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "Campaigns/createCampaign",
  async ({ companyId }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));
      const formattedDate = new Date()
        .toLocaleString()
        .replace(/\//g, "-")
        .replace(/:/g, "-")
        .replace(/, /g, "_");
      const name = `Untitled_(${formattedDate})`;

      if (!companyId) {
        throw new Error("Select a company to create a new campaign");
      }

      const { data } = await createCampaignApi(
        name,
        "DRAFT_TEMPLATE",
        companyId
      );

      await dispatch(loadDynamicItemsThunk({ companyId: companyId })).unwrap();

      dispatch(setCurrentCampaign(data));
      dispatch(setPhases([]));
      dispatch(setSteps([]));
      dispatch(addCampaign(data));

      return data;
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to create the campaign:`,
        e
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const editCampaignThunk = createAsyncThunk<
  void,
  { campaign: TCampaign; companyId: number | undefined; republish?: boolean },
  { rejectValue: TCustomError }
>(
  "Campaigns/renameCampaign",
  async ({ campaign, companyId, republish }, { rejectWithValue, dispatch }) => {
    try {
      if (republish) {
        const { data: isExecuting } = await computationApi(
          campaign.id,
          companyId
        ).validateCampaign();

        if (isExecuting) {
          throw new Error(
            "The phase couldn't be saved because the computation is in progress"
          );
        } else {
          await editCampaignApi(campaign, companyId);

          dispatch(setCurrentCampaign(campaign));
          dispatch(updateCampaign(campaign));

          await redeployCampaignToMicroSiteApi({
            campaignId: campaign.id,
            companyId,
          });
        }
      } else {
        await editCampaignApi(campaign, companyId);
        dispatch(setCurrentCampaign(campaign));
        dispatch(updateCampaign(campaign));
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to rename the campaign:`,
        e
      );

      return rejectWithValue(customError);
    }
  }
);

export const updateCampaignView = createAsyncThunk<
  void,
  { viewType: CampaignViewTypes },
  { state: RootState; rejectValue: TCustomError }
>("steps/updateCampaignViewType", ({ viewType }, { getState, dispatch }) => {
  dispatch(toggleIsFetching(true));

  const { steps, users } = getState();

  const step = steps.current;

  if (
    step?.hiddenDocView &&
    viewType === CampaignViewTypes.DOC &&
    users.userData.role !== "global_admin"
  ) {
    const firstStepVisibleDoc =
      steps.list.find((step) => !step.hiddenDocView) || null;

    dispatch(setCurrentStep(firstStepVisibleDoc));
    dispatch(setCurrentCampaignView(CampaignViewTypes.DOC));
  } else {
    dispatch(setCurrentCampaignView(CampaignViewTypes.DOC));
    dispatch(setCurrentCampaignView(viewType));
  }

  dispatch(toggleIsFetching(false));
});
