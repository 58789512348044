import { TAsset, TPhase } from "../globalTypes";
import { TCampaign } from "../store/slices/campaignsSlice";
import { TStep } from "../store/slices/stepsSlice";

type TStepData = TStep & {
  assets: Array<TAsset>;
};

export type TPhasesData = TPhase & {
  steps: Array<TStepData>;
};

export type TCampaignData = Omit<TCampaign, "extCompanyName"> & {
  environment: null;
  i18n: null;
  ccItems: null;
  phases: Array<TPhasesData>;
};

type TCampaignTransformedData = {
  steps: Array<TStep>;
  phases: Array<TPhase>;
  campaign: TCampaign;
  phase: TPhase | null;
  step: TStep | null;
};

const transformCampaignData = (
  data: TCampaignData,
  phaseId: number | undefined,
  stepId: number | undefined
): TCampaignTransformedData => {
  const {
    id,
    extCompanyId,
    aiModelId,
    type,
    name,
    phases,
    naturalId,
    microSiteContextFolder,
    mark,
  } = data;

  const transformedData: TCampaignTransformedData = {
    steps: [],
    phases: [],
    campaign: {
      id,
      extCompanyId,
      type,
      name,
      naturalId,
      microSiteContextFolder,
      aiModelId,
      mark,
      //"extCompanyName" prop needed in campaign page. Set hard to null here as it's missing in response
      extCompanyName: null,
    },
    phase: null,
    step: null,
  };

  phases.forEach((phase, index) => {
    const { steps, ...restPhase } = phase;
    transformedData.phases.push({ ...restPhase });

    if (phaseId === undefined) {
      if (index === 0) {
        transformedData.phase = restPhase;

        steps.forEach((step, index) => {
          const { assets, ...restStep } = step;
          transformedData.steps.push({ ...restStep });

          if (stepId === undefined && index === 0) {
            transformedData.step = restStep;
          }
        });
      }
    } else {
      if (restPhase.id === phaseId) {
        transformedData.phase = restPhase;

        steps.forEach((step) => {
          const { assets, ...restStep } = step;
          transformedData.steps.push({ ...restStep });

          if (restStep.id === stepId) {
            transformedData.step = restStep;
          }
        });
      }
    }
  });

  return transformedData;
};

export default transformCampaignData;
