import { useState } from "react";
import { Link } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../common/ui/alert-dialog";
import { Button } from "../common/ui/button";

export function AlertApp11() {
  const [open, setOpen] = useState(true);
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent onCloseAutoFocus={(e) => setOpen(false)}>
        <AlertDialogHeader>
          <AlertDialogTitle>
            🎉 Welcome to VelocityEngine v1.1!
          </AlertDialogTitle>
          <AlertDialogDescription>
            Great news… your account and campaigns have been successfully
            migrated to the latest version of VelocityEngine. Join us in v1.1 to
            pick up where you left off and check out all the new functionality.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <Link
            to="https://docs.google.com/document/d/1B5v4OPj0qiR9KFs3FKYa6F_Sp6z9P_QUdlP-2tnX_VY"
            target="_blank"
          >
            <Button variant="outline">What’s New in v1.1</Button>
          </Link>
          <Link to="https://app11.velocityengine.co/" target="_blank">
            <AlertDialogAction>Launch VE v1.1</AlertDialogAction>
          </Link>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
