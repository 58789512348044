import { AxiosResponse } from "axios";
import { TUserForm } from "../components/users/UserForm/UserForm";
import { TVEServerRes } from "../globalTypes";
import {
  TUserData,
  TUsersListItem,
  UserPermissions,
} from "../store/slices/usersSlice";
import axiosInstance from "../utils/axios/axiosInstance";

export const userLoginWithGoogleApi = (
  credential: string
): Promise<
  AxiosResponse<
    TVEServerRes & {
      user: TUserData;
    }
  >
> => {
  return axiosInstance.get(`/api/public/auth/login?g=${credential}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const userLoginApi = (): Promise<
  AxiosResponse<TVEServerRes & { user: TUserData }>
> => {
  return axiosInstance.get(`/api/public/auth`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const markUserApi = (
  userId: number,
  mark: boolean
): Promise<AxiosResponse<TVEServerRes>> => {
  return axiosInstance.put(`/api/secure/user/${userId}/mark`, { mark });
};

export const userLogoutApi = (): Promise<AxiosResponse<TVEServerRes>> => {
  return axiosInstance.get(`/api/public/auth/logout`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

type TGetPermissionsRes = TVEServerRes & {
  permissions: Array<UserPermissions>;
  cmApiKey: string | null;
};
export const getPermissionsApi = (
  companyId: number
): Promise<AxiosResponse<TGetPermissionsRes>> => {
  return axiosInstance.get(`/api/secure/user/permissions/${companyId}`, {
    headers: { "Content-Type": "application/json" },
  });
};

type TGetUsersListRes = TVEServerRes & {
  users: {
    users: Array<TUsersListItem>;
    totalUsers: number;
  };
};
export const getUsersListApi = ({
  pageNumber,
  pageSize,
}: {
  pageNumber: number;
  pageSize: number;
}): Promise<AxiosResponse<TGetUsersListRes>> => {
  return axiosInstance.get(`/api/secure/user/list/`, {
    headers: { "Content-Type": "application/json" },
    params: {
      pageNumber: pageNumber,
      pageSize: pageSize,
    },
  });
};

type TGetUserDataRes = TVEServerRes & {
  user: Omit<TUserData, "global_admin_api_key"> & {
    companies: {
      editor: Array<{ id: number; name: string }> | null;
      viewer: Array<{ id: number; name: string }> | null;
    };
  };
  userCompanies: Array<{ id: number; name: string }>;
};

export const getUserDataApi = (
  userId: number
): Promise<AxiosResponse<TGetUserDataRes>> => {
  return axiosInstance.get(`/api/secure/user/${userId}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const deleteUserApi = (
  userId: number
): Promise<AxiosResponse<TVEServerRes>> => {
  return axiosInstance.delete(`/api/secure/user/${userId}`, {
    headers: { "Content-Type": "application/json" },
  });
};

type TUserCompanyPermission = "viewer" | "editor";

export type TUserCompaniesCreateUser = {
  company_id: number;
  permission: TUserCompanyPermission;
};

export type TCreateOrUpdateUserProps = Omit<TUserForm, "editor" | "viewer"> & {
  companies: Array<TUserCompaniesCreateUser>;
};
export const createUserApi = (
  userData: TCreateOrUpdateUserProps
): Promise<AxiosResponse<TVEServerRes>> => {
  return axiosInstance.post(`/api/secure/user`, userData, {
    headers: { "Content-Type": "application/json" },
  });
};

export const updateUserApi = (
  userData: TCreateOrUpdateUserProps,
  userId: number
): Promise<AxiosResponse<TVEServerRes>> => {
  return axiosInstance.put(`/api/secure/user/${userId}`, userData, {
    headers: { "Content-Type": "application/json" },
  });
};
