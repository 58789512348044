import { Spin } from "antd";
import React from "react";
import { Icons } from "../../components/common/Icons";
import Pagination from "../../components/common/Pagination";
import { Button } from "../../components/common/ui/button";
import UserDrawer from "../../components/users/UserDrawer/UserDrawer";
import UsersList from "../../components/users/UsersList/UsersList";
import useGetUsersHook from "./hooks/useGetUsers.hook";

const UsersPage: React.FC = () => {
  const {
    users,
    userDrawerProps,
    setUserDrawerProps,
    isFetching,
    isRegularUser,
    updatePageSize,
    updatePage,
    total,
    pageSize,
    pageNumber,
    refreshUsers,
  } = useGetUsersHook();

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal">
          Users
        </h1>
        {!isRegularUser && (
          <Button
            icon={Icons.Plus}
            className="rounded-full"
            onClick={() => {
              setUserDrawerProps({ visible: true });
            }}
          >
            Add New User
          </Button>
        )}
      </div>
      <div className="py-[24px]">
        <Pagination
          updatePageSize={updatePageSize}
          updatePage={updatePage}
          total={total}
          pageSize={pageSize}
          pageNumber={pageNumber}
        />
      </div>
      <Spin spinning={isFetching}>
        <UsersList
          users={users}
          userDrawerProps={userDrawerProps}
          setUserDrawerProps={setUserDrawerProps}
          refreshUsers={refreshUsers}
        />
      </Spin>
      <div className="pt-3">
        <Pagination
          updatePageSize={updatePageSize}
          updatePage={updatePage}
          total={total}
          pageSize={pageSize}
          pageNumber={pageNumber}
        />
      </div>
      <UserDrawer
        userDrawerProps={userDrawerProps}
        setUserDrawerProps={setUserDrawerProps}
      />
    </div>
  );
};

export default UsersPage;
