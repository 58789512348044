import {
  BookmarkSquareIcon,
  CheckIcon,
  MegaphoneIcon,
} from "@heroicons/react/16/solid";
import { format } from "date-fns";
import dayjs from "dayjs";
import { X } from "lucide-react";
import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteCompanyApi,
  getCompanyApi,
  markCompanyApi,
  updateCompanyApi,
} from "src/api/companies.api";
import { ArrowRightIcon, DeleteIcon, Icons } from "src/components/common/Icons";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import { Button } from "src/components/common/ui/button";
import { TableCell, TableRow } from "src/components/common/ui/table";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { removeCompany } from "src/store/slices/companiesListSlice";
import { getMessageApi } from "../../store/slices/appSlice";
import { TCompany } from "../../store/slices/companiesSlice";
import handleRequestError from "../../utils/handleRequestError";
import DeleteWithFilesModal from "../common/modals/DeleteWithFilesModal/DeleteWithFilesModal";
import { CompanyDrawerProps } from "./CompanyDrawer/CompanyDrawer";

type Props = {
  item: TCompany;
  setCompanyDrawerProps: React.Dispatch<
    React.SetStateAction<CompanyDrawerProps>
  >;
  updateList: () => Promise<void>;
};

const CompaniesListItem: FC<Props> = ({
  item,
  setCompanyDrawerProps,
  updateList,
}) => {
  const { id, website, founding_at, name } = item;
  const messageApi = getMessageApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isRegularUser, isGlobalAdmin } = useCurrentUser();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onDelete = async (removeFiles: boolean) => {
    try {
      dispatch(removeCompany(id));
      await deleteCompanyApi(id, removeFiles);
      await updateList();

      messageApi.success("Company deleted successfully");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const onSaveEdit = async (formData: FormData) => {
    // try catch is not needed, error handling is in the component CompanyDrawer
    await updateCompanyApi(formData, id);
    await updateList();

    messageApi.success("Company updated successfully");
  };

  const onOpenEdit = async () => {
    try {
      const { data } = await getCompanyApi(id);
      const { company } = data;

      const formValues = {
        name: company.name,
        foundingDate: dayjs(company.founding_at),
        website: company.website,
        logo: company.logo,
        mark: company.mark,
      };

      setCompanyDrawerProps({
        visible: true,
        onSubmitCb: onSaveEdit,
        initialValues: formValues,
      });
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const isMarked = item.mark;

  const onMark = async () => {
    await markCompanyApi(id, !isMarked);
    await updateList();
  };

  const menuItems = [
    {
      key: "details",
      label: "Details",
      onClick: () => navigate(`/admin/companies/${id}/details`),
      icon: ArrowRightIcon,
    },
    isGlobalAdmin && {
      key: "mark",
      label: isMarked ? "Unmark" : "Mark",
      onClick: () => onMark(),
      icon: isMarked ? X : CheckIcon,
    },
    {
      key: "campaigns",
      label: "View Campaigns",
      onClick: () =>
        navigate(
          `/admin/campaigns?companyId=${id}&campaignType=ALL&page=1&pageSize=50&backUrl=${encodeURIComponent("/admin/companies")}`
        ),
      icon: MegaphoneIcon,
    },
    !isRegularUser && {
      key: "edit",
      label: "Edit",
      onClick: () => onOpenEdit(),
      icon: Icons.edit,
    },
    !isRegularUser && {
      key: "delete",
      label: "Delete",
      onClick: () => setIsDeleteModalOpen(true),
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  return (
    <>
      <TableRow
        key={id}
        onClick={() => navigate(`/admin/companies/${id}/details`)}
        className="cursor-pointer relative"
      >
        <TableCell>
          <span className="flex items-center">
            {name}
            {!!isMarked && isGlobalAdmin && (
              <BookmarkSquareIcon className="size-[20px] ml-1" />
            )}
          </span>
        </TableCell>
        <TableCell>{website?.replace("https://", "")}</TableCell>
        <TableCell>
          {founding_at
            ? format(item.founding_at, "MMMM d, yyyy h:mm a zzz")
            : "-"}
        </TableCell>
        <TableCell
          className="flex items-center justify-end"
          onClick={(e) => e.stopPropagation()}
        >
          <MenuDropdown
            items={menuItems}
            dropdownClassName="w-[190px]"
            align="end"
          >
            <Button
              variant={"ghostPrimary"}
              className="size-[40px] p-2 flex justify-end"
            >
              <Icons.MoreDots className="size-[24px]" />
            </Button>
          </MenuDropdown>
        </TableCell>
      </TableRow>
      {isDeleteModalOpen && (
        <DeleteWithFilesModal
          entity="Company"
          onDelete={onDelete}
          title={name}
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
        />
      )}
    </>
  );
};

export default CompaniesListItem;
