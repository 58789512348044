import clsx from "clsx";
import { CheckIcon, X } from "lucide-react";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserApi,
  getUsersListApi,
  markUserApi,
} from "../../../api/user.api";
import { roleSettings } from "../../../constants";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { UserDrawerProps } from "../../../pages/users/hooks/useGetUsers.hook";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  selectUsersListState,
  setUsers,
} from "../../../store/slices/usersListSlice";
import {
  TUsersListItem,
  UserPermissions,
} from "../../../store/slices/usersSlice";
import { AppDispatch } from "../../../store/store";
import handleRequestError from "../../../utils/handleRequestError";
import { DeleteIcon, Icons } from "../../common/Icons";
import MenuDropdown, { MenuDropdownItem } from "../../common/MenuDropdown";
import DeleteWithTitleConfirmationModal from "../../common/modals/DeleteWithTitleConfirmationModal/DeleteWithTitleConfirmationModal";
import { Badge } from "../../common/ui/badge";
import { Button } from "../../common/ui/button";
import { TableCell, TableRow } from "../../common/ui/table";
import { BookmarkSquareIcon } from "@heroicons/react/16/solid";

type Props = {
  item: TUsersListItem;
  setUserDrawerProps: (props: UserDrawerProps) => void;
  refreshUsers: () => Promise<void>;
};

const UsersListItem: FC<Props> = ({
  item,
  setUserDrawerProps,
  refreshUsers,
}) => {
  const { isRegularUser, hasPermission, isGlobalAdmin } = useCurrentUser();
  const { id, email, first_name, last_name, companies, role, position, mark } =
    item;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const { pageNumber, pageSize } = useSelector(selectUsersListState);

  const onDelete = async () => {
    try {
      await deleteUserApi(id);

      const { data } = await getUsersListApi({
        pageSize,
        pageNumber,
      });
      const { users, totalUsers } = data.users;
      dispatch(setUsers({ users, total: totalUsers }));

      messageApi.success("User deleted successfully");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const onMark = async () => {
    try {
      await markUserApi(id, !mark);
      await refreshUsers();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const menuItems = [
    !isRegularUser && {
      key: "1",
      label: "Edit",
      onClick: () =>
        setUserDrawerProps({
          visible: true,
          userId: item.id,
          fullName: `${item.first_name} ${item.last_name}`,
        }),
      icon: Icons.edit,
    },
    isGlobalAdmin && {
      key: "mark",
      label: mark ? "Unmark" : "Mark",
      onClick: () => onMark(),
      icon: mark ? X : CheckIcon,
    },
    (!isRegularUser ||
      hasPermission(UserPermissions.ROLE_COMPANY_REGISTRATION)) && {
      key: "2",
      label: "Delete",
      onClick: () => setIsDeleteModalOpen(true),
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const {
    label: badgeLabel = "",
    className: badgeClassName = "",
    icon: Icon,
  } = roleSettings[role];

  return (
    <>
      <TableRow
        key={id}
        className={clsx("relative text-[#475569] font-sans h-[48px]")}
      >
        <TableCell className="font-medium text-[#0F172A]">
          <span className="flex items-center">
            {first_name} {last_name}
            {!!mark && isGlobalAdmin && (
              <BookmarkSquareIcon className="size-[20px] ml-1" />
            )}
          </span>
        </TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>
          {companies.map((company) => {
            return (
              <div key={company.name}>
                {`${company.name} (${company.role})`}
              </div>
            );
          })}
        </TableCell>
        <TableCell>
          <Badge className={badgeClassName} variant={"outline"}>
            {Icon && <Icon className="size-4 mr-[4px] text-[#64748B]" />}
            <span className="text-[#64748B] text-[11px]">{badgeLabel}</span>
          </Badge>
        </TableCell>
        <TableCell>{position}</TableCell>
        <TableCell className="relative" onClick={(e) => e.stopPropagation()}>
          <div className="flex justify-end items-center">
            {!!menuItems.length && (
              <MenuDropdown
                items={menuItems}
                dropdownClassName="w-[190px]"
                align="end"
              >
                <Button variant={"ghostPrimary"} className="size-[40px] p-2">
                  <Icons.MoreDots className="size-[24px]" />
                </Button>
              </MenuDropdown>
            )}
          </div>
        </TableCell>
      </TableRow>
      {isDeleteModalOpen && (
        <DeleteWithTitleConfirmationModal
          entity="User"
          onDelete={onDelete}
          title={first_name}
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
        />
      )}
    </>
  );
};

export default UsersListItem;
